<div class="nav-container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <img alt="Lorentz" class="lorentz-logo" src="/assets/LOGO_LORENTZ_WHITE.svg">
      <nav class="nav-content" *appIsAuthenticated>
        <a mat-button matTooltip="open the product data" routerLink="/data" routerLinkActive="active">
          <span>Product data</span>
        </a>
        <a mat-button matTooltip="open the pump simulation" (click)="cleanData()" routerLink="/simulations"
          routerLinkActive="active">
          <span>Pump simulation</span>
        </a>
        <a mat-button *ngIf="(isAdmin$ | async)" matTooltip="open data upload/download" routerLink="/upload"
          routerLinkActive="active">
          <span>Data upload/download</span>
        </a>
             </nav>
      <div class="flex-spacer"></div>
      <div>
        <button mat-button *ngIf="(isNotAuthenticated$ | async)" routerLink="/login">Login</button>
        <button mat-button *ngIf="(isAuthenticated$ | async)" (click)="logout()">Logout</button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
